import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const RangeBarChart = (props) => {

  const [datamap,setDatamap] = useState()

  const cData = props.chatData
  // console.log(cData)

  const chartData = cData.chartData
  // console.log(chartData)

  const convertedData = [];

// Iterate through the X_axis and Y_axis arrays to create "data" objects
for (let i = 0; i < chartData.X_axis.length; i++) {
  const xValue = chartData.X_axis[i];
  const yValue = chartData.Y_axis[i];
  if (i === 0) {
      convertedData.push({ "x": xValue, "y": [0, yValue] });
  } else {
      const previousX = convertedData[i - 1].x;
      const previousY = convertedData[i - 1].y[1];
      if (i === chartData.X_axis.length - 1) {
          // Handle the last push differently
          convertedData.push({ "x": xValue, "y": [0, yValue] });
      } else {
          convertedData.push({ "x": `${xValue}`, "y": [previousY, previousY + yValue] });
      }
  }
}








// Convert the result to JSON if needed
const convertedDataJSON = JSON.stringify(convertedData);

// console.log(convertedDataJSON);
const convertedDataArray = JSON.parse(convertedDataJSON);


    const formatNumber = (value) => {
        if (value >= 1000) {
          const formattedValue = (value / 1000).toFixed(2);
          return `${formattedValue}k`;
        }
        return value;
      };
  const options = {
    series: [
      {
        data: convertedDataArray
      }
    ],
    chart: {
      type: 'rangeBar',
      height: 150,
      toolbar: {
        show: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50px',

      },
    },
    dataLabels: {
      enabled: true,
      offsetY: 20,

      formatter: function (val) {
        return formatNumber(val);
      },
      style: {
        fontSize: '8px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        colors: ["#253978"]
    }
      
    },
    stroke: {
      dashArray: 2,
      curve: 'smooth',

    },
    fill: {
        type: 'gradient',
        colors: ['#bb1ccc', '#E91E63', '#9C27B0'],
        gradient: {
            colorFrom: '#bb1ccc',
            colorTo: '#bb1ccc',
            stops: [0, 100],
            opacityFrom: 1,
            opacityTo: 0.5,
        },
    },
   
  };

  return (
    <div>
      <ReactApexChart options={options} series={options.series} type="rangeBar" height={350} />
    </div>
  );
};

export default RangeBarChart;
