import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import Box from "@mui/material/Box";
import SendIcon from "@mui/icons-material/Send";
import icons from "../assets/img/Layer_1.png";
import Card from "@mui/material/Card";
import PieDonutChart from "../Chats/PieChats";
import AreaChart from "../Chats/AreaChats";
import ProgressBarChart from "../Chats/ProgressBar";
import HistoryIcon from "@mui/icons-material/History";
import WaterfallChart from "../Chats/WaterfallChart";
import LineChart from "../Chats/LineChats";
import { FaTrash } from "react-icons/fa";
import nodataImage from "../assets/img/3.png";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { useReactToPrint } from "react-to-print";
import Logo from "../assets/img/aip_n.svg";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Spinner2 from "../Layout/Spinner2";
import BarChart from "../Chats/BarChats";
import $ from 'jquery'
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: (
      <SentimentVeryDissatisfiedIcon sx={{ fontSize: "34px" }} color="error" />
    ),
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon sx={{ fontSize: "34px" }} color="error" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon sx={{ fontSize: "34px" }} color="warning" />,
    label: "Neutral",
  },
  4: {
    icon: (
      <SentimentSatisfiedAltIcon sx={{ fontSize: "34px" }} color="success" />
    ),
    label: "Satisfied",
  },
  5: {
    icon: (
      <SentimentVerySatisfiedIcon sx={{ fontSize: "34px" }} color="success" />
    ),
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const Home = () => {
  const [userInput, setUserInput] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [yesDownload, setyesDownload] = useState(false);
  const [confirmations, setconfirmations] = useState(false);
  const [tabValue, setTabValue] = useState("tab1"); // Initialize with 'tab1' as the default tab
  const [likeCount, setLikeCount] = useState(0);
  const [dislikeCount, setDislikeCount] = useState(0);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [AskQuestion,setAskQuestion] = useState("")
  const [errorData,setErrorData] = useState(false)

  const [messageIndex, setMessageIndex] = useState(0);
  const messages = [
    { text: 'Analysing the question...', delay: 5000 },
    { text: 'Collecting Data...', delay: 6000 },
    { text: 'Synthesizing the insights...', delay: 6000 },
    { text: 'Preparing reports...', delay: 7000 },
  ];

  useEffect(() => {
    if (isLoading && messageIndex < messages.length) {
      const timer = setTimeout(() => {
        setMessageIndex(messageIndex + 1);
      }, messages[messageIndex].delay);

      return () => clearTimeout(timer);
    }
  }, [isLoading, messageIndex]);

  const handleLike = () => {
    if (!liked) {
      setLikeCount(likeCount + 1);
      setLiked(true);
      if (disliked) {
        setDisliked(false);
        setDislikeCount(dislikeCount - 1);
      }
    } else {
      setLikeCount(likeCount - 1);
      setLiked(false);
    }
  };

  const handleDislike = () => {
    if (!disliked) {
      setDislikeCount(dislikeCount + 1);
      setDisliked(true);
      if (liked) {
        setLiked(false);
        setLikeCount(likeCount - 1);
      }
    } else {
      setDislikeCount(dislikeCount - 1);
      setDisliked(false);
    }
  };

  const [data, setData] = useState([]);
  const [Askdata, setAskdata] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(1); // Initial index for paragraphData

  const chatContainerRef = useRef(null);
  const componentRef_1 = useRef(null);

  const [typingMessage, setTypingMessage] = useState({ text: "", isBot: true });
  const [History, SetHistory] = useState(
    JSON.parse(window.sessionStorage.getItem("History")) || []
  );

  const faq = [
    {
        "id":1,
        "text": "Trend Analysis on Monthly level"
    },
    {
        "id":2,
        "text": "Trend Analysis on YTD level"
    },
    {
        "text": "Trend Analysis",
        "id":3,
    },
    {
        "text": "Variance Analysis on monthly level",
        "id":4,

    },
    {
        "text": "Variance analysis on YTD level",
        "id":5,
    },
    {
        "text": "Variance analysis",
        "id":6,
    },
    // {
    //     "text": "Decomposition for profit center at monthly level",
    //     "id":7,
    // },
    // {
    //     "text": "Decomposition for profit center at YTD level",
    //     "id":8,
    // },
    // {
    //     "text": "Decomposition for Accounting profile on monthly level",
    //     "id":9,
    // },
    // {
    //     "text": "Decomposition for Accounting profile on YTD level",
    //     "id":10,
    // },
    // {
    //     "text": "Breakdown of Profit center",
    //     "id":11,
    // },
    // {
    //     "text": "Breakdown of Accounting Profile",
    //     "id":12,
    // },
    // {
    //     "text": "Variance analysis profit center monthly",
    //     "id":13,
    // },
    // {
    //     "text": "Variance analysis profit center YTD",
    //     "id":14,
    // },
    // {
    //     "text": "Decomposition analysis profit center monthly",
    //     "id":15,
    // },
    // {
    //     "text": "Decomposition analysis Accounting profile monthly",
    //     "id":16,
    // },
    // {
    //     "text": "Decomposition analysis Accounting profile YTD",
    //     "id":17,
    // },
    // {
    //     "text": "Decomposition Analysis profit center YTD",
    //     "id":18,
    // },
    // {
    //     "text": "Variance Analysis Accounting profile Monthly",
    //     "id":19,
    // },
    // {
    //     "text": "Variance Analysis Accounting profile YTD",
    //     "id":20,
    // }
]
  // console.log(chatContainerRef.current);

  // const scrollToBottom = () => {
  //   console.log("calling...")
  //   console.log(chatContainerRef.current)

  //   if (chatContainerRef.current) {
  //     chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  //   }
  // };

  // const handleManualScroll = () => {
  //   setAutoScrollEnabled(false);
  // };

  // When you want to re-enable automatic scrolling, call this function
  // const enableAutoScroll = () => {
  //   setAutoScrollEnabled(true);
  //   scrollToBottom(); // Scroll to the bottom immediately when re-enabled
  // };

  // const scrollToBottom = () => {
  //   const chatContainer = chatContainerRef.current;

  //   // Check if the user has manually scrolled up
  //   const isUserScrolledUp = chatContainer.scrollTop < chatContainer.scrollHeight - chatContainer.clientHeight;

  //   // Only scroll to the bottom if automatic scrolling is enabled
  //   if (!isUserScrolledUp && isAutoScrollEnabled) {
  //     chatContainerRef.current?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  //   }
  // };

  const handleExampleClick = async (exampleText,id) => {
    // Set the clicked exampleText as the userInput
    // setUserInput(exampleText);
    setMessageIndex(0)
    $('.faqActive').removeClass('faqActive');
    $(`#faqsId_${id}`).addClass('faqActive')
    setTypingMessage({ text: "", isBot: true });
    const sessionHistory =
      JSON.parse(window.sessionStorage.getItem("History")) || [];
    SetHistory(sessionHistory);
    // Only add the exampleText to history if it's not already present
    if (!sessionHistory.includes(exampleText)) {
      sessionHistory.push(exampleText);
      window.sessionStorage.setItem("History", JSON.stringify(sessionHistory));
    }

    setLoading(true);
    setErrorData(false)
    setAskQuestion(exampleText)

    // Simulate bot thinking for 2 seconds (adjust this as needed)
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // Fetch data
    const baseurl = "https://asbap-api-dev-uk-s.azurewebsites.net/ask";
    const requestBody = 
      {
        question:exampleText
      }
    ;
    try {
      const response = await axios.post(baseurl, requestBody);

      if (response.status === 200) {
        const jsonData = response.data;
        setData(jsonData);
        setTimeout(() => {
          setLoading(false);
        }, 25000);
        setyesDownload(false);
        setconfirmations(false);
        setUserInput("")
      }
      if(response.status === 500){
        setErrorData(true)
      }
    } catch (err) {
      console.error("Error:", err);
      setErrorData(true)

    } finally {
      setTimeout(() => {
        setLoading(false);

      }, 25000);
      setTypingMessage({ text: "", isBot: true });
    }
  };

  const printSegment = useReactToPrint({
    content: () => {
      const tableStat_1 = componentRef_1.current.cloneNode(true);

      const PrintElem = document.createElement("div");
      const header = `<div class="watermark">IVA Logo</div>`;
      PrintElem.innerHTML = header;

      PrintElem.appendChild(tableStat_1);

      return PrintElem;
    },
  });

  // const handleSendClick = async () => {
  //   // Set the clicked exampleText as the userInput
  //   alert(userInput);
  //   setTypingMessage({ text: "", isBot: true });
  //   const sessionHistory =
  //     JSON.parse(window.sessionStorage.getItem("History")) || [];
  //   // Only add the exampleText to history if it's not already present
  //   if (!sessionHistory.includes(userInput)) {
  //     sessionHistory.push(userInput);
  //     window.sessionStorage.setItem("History", JSON.stringify(sessionHistory));
  //   }

  //   setLoading(true);

  //   // Simulate bot thinking for 2 seconds (adjust this as needed)
  //   await new Promise((resolve) => setTimeout(resolve, 2000));

  //   // Fetch data
  //   const baseurl = `https://asbap-api-dev-uk-s.azurewebsites.net/ask`;
  //   try {
  //     const response = await fetch(baseurl);

  //     if (response.ok) {
  //       const jsonData = await response.json();
  //       setData(jsonData);
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     console.error("Error:", err);
  //   } finally {
  //     setLoading(false);
  //     setTypingMessage({ text: "", isBot: true });
  //   }
  // };

  const handleSendClick = async () => {
    // Set the clicked exampleText as the userInput
    // alert(userInput);
    setMessageIndex(0)
    setTypingMessage({ text: "", isBot: true });
    const sessionHistory =
      JSON.parse(window.sessionStorage.getItem("History")) || [];
    // Only add the exampleText to history if it's not already present
    if (!sessionHistory.includes(userInput)) {
      sessionHistory.push(userInput);
      window.sessionStorage.setItem("History", JSON.stringify(sessionHistory));
    }
    setErrorData(false)
    setLoading(true);
    setAskQuestion(userInput)

    // Simulate bot thinking for 2 seconds (adjust this as needed)
  
    // Axios POST request with a request body
    const baseurl = "https://asbap-api-dev-uk-s.azurewebsites.net/ask";
    const requestBody = 
      {
        question:userInput
      }
    ;
  
    try {
      const response = await axios.post(baseurl, requestBody);
  
      if (response.status === 200) {
        const jsonData = response.data;
        setData(jsonData);
        setUserInput("")
        setTimeout(() => {
          setLoading(false);

        }, 25000);

      }
      if(response.status === 500){
        setErrorData(true)
      }
    } catch (err) {
      console.error("Error:", err);
      setErrorData(true)

    } finally {
      setTimeout(() => {
        setLoading(false);

      }, 25000);      
      setTypingMessage({ text: "", isBot: true });
    }
  };
  
  const clearSessionData = () => {
    // Clear the History array from session storage
    window.sessionStorage.removeItem("History");
    SetHistory(JSON.parse(window.sessionStorage.getItem("History")) || []);
    // You can add additional session data clearing logic here if needed
  };

  const removeFromSessionHistory = (itemToRemove) => {
    SetHistory(JSON.parse(window.sessionStorage.getItem("History")) || []);

    // Load the current history from session storage
    const sessionHistory =
      JSON.parse(window.sessionStorage.getItem("History")) || [];

    // Find the index of the item to remove
    const indexToRemove = sessionHistory.indexOf(itemToRemove);
    SetHistory(JSON.parse(window.sessionStorage.getItem("History")) || []);

    // Check if the item is in the history array
    if (indexToRemove !== -1) {
      // Remove the item from the history array
      sessionHistory.splice(indexToRemove, 1);

      // Update session storage with the modified history array
      window.sessionStorage.setItem("History", JSON.stringify(sessionHistory));
      SetHistory(JSON.parse(window.sessionStorage.getItem("History")) || []);
    }
  };

  const ChangeTab = (data) => {
    setTabValue(data);
  };


  // useEffect(() => {
  //   let intervalId;

  //   if (data.length !==0) {
  //     // Start interval when component mounts
  //     intervalId = setInterval(() => {
  //       // Push the current paragraphData to Askdata
  //       if (data[0][`paragraphData${currentIndex}`]) { // Check if the current paragraphData exists
  //         setAskdata(prevData => ([
  //           ...prevData,
  //           { [`paragraphData${currentIndex}`]: data[0][`paragraphData${currentIndex}`] }
  //         ]));         
  //         // Update currentIndex for the next paragraphData
  //         setCurrentIndex(prevIndex => prevIndex + 1);
         
  //         // const statisticElement = document.getElementById("statistic");
  //         // if (statisticElement) {
  //         //   window.scrollTo({
  //         //     top: 0,
  //         //     behavior: "smooth"
  //         //   });         
  //         //  }
  
  //         // Stop interval when all paragraphData are pushed
  //         if (currentIndex > Object.keys(data).length) {
  //           clearInterval(intervalId);
  //         }
  //       }
     
  //     }, 3000);
  //   }

  //   if(isLoading){
  //     setAskdata([])
  //     setCurrentIndex(1)

  //   }

  //   // Cleanup function to clear interval when component unmounts
  //   return () => clearInterval(intervalId);

  // }, [isLoading, currentIndex, data]);

  // console.log(currentIndex);

  return (
    <div id="homePage" style={{ overflow: "hidden" }}>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="mainHome">
            <div ref={chatContainerRef} id="mainChatContent">
              <div className="faqSections">
                <div className="">
                  <div className="card text-center">
                    <div className="card-body">
                      <div id="FaqSectionsBot">
                        <div className="tabChatroom">
                          <span
                            id="tab1"
                            onClick={() => ChangeTab("tab1")}
                            className={`chatRoomTab ${
                              tabValue === "tab1" ? "tabSelected" : ""
                            }`}
                          >
                            FAQ{" "}
                          </span>

                          <span
                            id="tab2"
                            onClick={() => ChangeTab("tab2")}
                            className={`chatRoomTab ${
                              tabValue === "tab2" ? "tabSelected" : ""
                            }`}
                          >
                            History
                          </span>
                        </div>
                      </div>

                      {tabValue === "tab1" && (
                        <>
                          {faq.length !== 0 && (
                            <div>
                              {faq.map((item, index) => (
                                <div
                                  key={index}
                                  className="mt-3 justify-content-center"
                                >
                                  <div
                                    id={`faqsId_${item.id}`}
                                    className="bg-dark-card"
                                  >
                                    <div className="text-start faqtext">
                                      <span
                                        onClick={() =>
                                          handleExampleClick(item.text, item.id)
                                        }
                                      >
                                        {item.text}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}

                          {faq.length === 0 && (
                            <>
                              <div
                                style={{
                                  height: "60vh",
                                  display: "grid",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "absolute",
                                  top: 30,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                }}
                              >
                                <div>
                                  <img
                                    src={nodataImage}
                                    width={200}
                                    alt="nodata"
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "grid",
                                    gridGap: "6px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      color: "gray",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    No Frequently Asked Questions
                                  </span>
                                  <span
                                    style={{
                                      width: "100%",
                                      display: "block",
                                      margin: "auto",
                                      color: "gray",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    Despite the absence of an FAQ section, we
                                    are here to assist you. Feel free to reach
                                    out to our support team with any questions
                                    or concerns you may have.{" "}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {tabValue === "tab2" && (
                        <>
                          {History.length === 0 && (
                            <>
                              <div
                                style={{
                                  height: "60vh",
                                  display: "grid",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "absolute",
                                  top: 30,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                }}
                              >
                                <div>
                                  <img
                                    src={nodataImage}
                                    width={200}
                                    alt="nodata"
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "grid",
                                    gridGap: "6px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      color: "gray",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    No History Available
                                  </span>
                                  <span
                                    style={{
                                      width: "100%",
                                      display: "block",
                                      margin: "auto",
                                      color: "gray",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    We do not have any history data at the
                                    moment{" "}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}

                          {History.length !== 0 && (
                            <>
                              {History.map((item, index) => {
                                return (
                                  <>
                                    <div
                                      key={index}
                                      className="mt-3 justify-content-center"
                                    >
                                      <div
                                        id={`faqsId_${index}`}
                                        className="bg-dark-card"
                                      >
                                        <div
                                          style={{
                                            gridGap: "7px",
                                            alignItems: "baseline",
                                          }}
                                          className="text-start faqtext"
                                        >
                                          <span
                                            onClick={() =>
                                              handleExampleClick(item, index)
                                            }
                                          >
                                            {item}
                                          </span>

                                          <span
                                            style={{
                                              cursor: "pointer",
                                              display: "grid",
                                            }}
                                            onClick={(e) =>
                                              removeFromSessionHistory(item)
                                            }
                                          >
                                            <FaTrash
                                              fontSize={12}
                                              style={{
                                                color: "#253978",
                                                paddingTop: "2px",
                                              }}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div id="statistic1">
                <div id="statistic">
                  {isLoading && (

                    <div style={{height:"100%"}}>

                    <div
                    style={{
                      textAlign: "left",
                      paddingTop: "20px",
                      display: "block",
                      paddingLeft: "20px",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}
                    >
                    Ask: {AskQuestion}
                    </div>

                    <div
                      style={{
                        height: "100%",
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        justifyItems:"center"
                      }}
                    >
                     

                      <div id="typingid">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>

                      <span style={{ padding: "20px",fontSize:"24px" }}>
                        {messageIndex < messages.length
                          ? messages[messageIndex].text
                          : "Preparing reports..."}
                      </span>
                    </div>

                    </div>
                  )}

                  {!isLoading && !errorData && (
                    <>
                      {AskQuestion !== "" && (
                        <div
                          style={{
                            textAlign: "left",
                            paddingTop: "20px",
                            display: "block",
                            paddingLeft: "20px",
                            fontSize: "20px",
                            fontWeight: "700",
                          }}
                        >
                          Ask: {AskQuestion}
                        </div>
                      )}

                      {data.map((item, index) => (
                        <div key={index}>
                          <Box
                            sx={{
                              display: "grid",
                              gap: "10px",
                              padding: "10px",
                            }}
                          >
                            {/* Card for paragarphData1 */}
                            <div
                              ref={componentRef_1}
                              style={{
                                display: "grid",
                                gap: "10px",
                                padding: "10px",
                                width: "100%",
                              }}
                              id="mainContainer_id"
                            >
                              <div
                                style={{
                                  display: "grid",
                                  justifyContent: "left",
                                  gridTemplateColumns: "repeat(1,100%)",
                                  gridGap: "10px",
                                }}
                              >
                                {item.paragraphData1 !== undefined && (
                                  <Card
                                    className="cardBoxCss cardBox1"
                                    variant="outlined"
                                  >
                                    <div
                                      style={{
                                        display: "grid",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span className="headerTextItems">
                                          {" "}
                                          {item.paragraphData1.HeaderText}
                                        </span>

                                        <div className="like-dislike-container">
                                          <IconButton
                                            onClick={handleLike}
                                            color={
                                              liked ? "primary" : "default"
                                            }
                                          >
                                            <ThumbUpIcon />
                                          </IconButton>
                                          <Typography variant="subtitle1">
                                            {likeCount}
                                          </Typography>
                                          <IconButton
                                            onClick={handleDislike}
                                            color={
                                              disliked ? "primary" : "default"
                                            }
                                          >
                                            <ThumbDownIcon />
                                          </IconButton>
                                          <Typography variant="subtitle1">
                                            {dislikeCount}
                                          </Typography>
                                        </div>
                                      </span>
                                      <span className="headerTextItems1">
                                        {item.paragraphData1.paragraphText1}
                                      </span>
                                      <span className="headerTextItems1">
                                        {item.paragraphData1.paragarphText2}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        display: "grid",
                                        alignItems: "center",
                                        alignContent: "center",
                                        width: "100%",
                                      }}
                                    >
                                      {item.paragraphData1.Chart1.chartType ===
                                        "line" && (
                                        <LineChart
                                          chatData={item.paragraphData1.Chart1}
                                        />
                                      )}

                                      {item.paragraphData1.Chart1.chartType ===
                                        "pie" && (
                                        <PieDonutChart
                                          chatData={item.paragraphData1.Chart1}
                                        />
                                      )}

                                      {item.paragraphData1.Chart1.chartType ===
                                        "bridge chart" && (
                                        <WaterfallChart
                                          chatData={item.paragraphData1.Chart1}
                                        />
                                      )}

                                      {item.paragraphData1.Chart1.chartType ===
                                        "Bar" && (
                                        <BarChart
                                          chatData={item.paragraphData1.Chart1}
                                        />
                                      )}
                                    </div>
                                  </Card>
                                )}
                              </div>

                              <div className="card-layout-secrion">
                                {/* Card for paragarphData2 */}
                                {item.paragraphData2 !== undefined && (
                                  <Card
                                    className="cardBoxCss"
                                    variant="outlined"
                                  >
                                    <div
                                      style={{
                                        display: "grid",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span className="headerTextItems">
                                          {item.paragraphData2.HeaderText}
                                        </span>
                                        <div className="like-dislike-container">
                                          <IconButton
                                            onClick={handleLike}
                                            color={
                                              liked ? "primary" : "default"
                                            }
                                          >
                                            <ThumbUpIcon />
                                          </IconButton>
                                          <Typography variant="subtitle1">
                                            {likeCount}
                                          </Typography>
                                          <IconButton
                                            onClick={handleDislike}
                                            color={
                                              disliked ? "primary" : "default"
                                            }
                                          >
                                            <ThumbDownIcon />
                                          </IconButton>
                                          <Typography variant="subtitle1">
                                            {dislikeCount}
                                          </Typography>
                                        </div>
                                      </span>

                                      <span className="headerTextItems1">
                                        {item.paragraphData2.paragraphText1}
                                      </span>
                                      <span className="headerTextItems1">
                                        {item.paragraphData2.paragraphText3}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        display: "grid",
                                        alignItems: "center",
                                        alignContent: "center",
                                        width: "100%",
                                      }}
                                    >
                                      {item.paragraphData2.Chart1.chartType ===
                                        "line" && (
                                        <LineChart
                                          chatData={item.paragraphData2.Chart1}
                                        />
                                      )}

                                      {item.paragraphData2.Chart1.chartType ===
                                        "pie" && (
                                        <PieDonutChart
                                          chatData={item.paragraphData2.Chart1}
                                        />
                                      )}

                                      {item.paragraphData2.Chart1.chartType ===
                                        "bridge chart" && (
                                        <WaterfallChart
                                          chatData={item.paragraphData2.Chart1}
                                        />
                                      )}

                                      {item.paragraphData2.Chart1.chartType ===
                                        "Bar" && (
                                        <BarChart
                                          chatData={item.paragraphData2.Chart1}
                                        />
                                      )}
                                    </div>
                                  </Card>
                                )}

                                {/* Card for paragarphData3 */}
                                {item.paragraphData3 !== undefined && (
                                  <Card
                                    className="cardBoxCss"
                                    variant="outlined"
                                  >
                                    <div
                                      style={{
                                        display: "grid",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span className="headerTextItems">
                                          {item.paragraphData3.HeaderText}
                                        </span>
                                        <div className="like-dislike-container">
                                          <IconButton
                                            onClick={handleLike}
                                            color={
                                              liked ? "primary" : "default"
                                            }
                                          >
                                            <ThumbUpIcon />
                                          </IconButton>
                                          <Typography variant="subtitle1">
                                            {likeCount}
                                          </Typography>
                                          <IconButton
                                            onClick={handleDislike}
                                            color={
                                              disliked ? "primary" : "default"
                                            }
                                          >
                                            <ThumbDownIcon />
                                          </IconButton>
                                          <Typography variant="subtitle1">
                                            {dislikeCount}
                                          </Typography>
                                        </div>
                                      </span>
                                      <span className="headerTextItems1">
                                        {item.paragraphData3.paragraphText1}
                                      </span>
                                      <span className="headerTextItems1">
                                        {item.paragraphData3.paragarphText2}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        display: "grid",
                                        alignItems: "center",
                                        alignContent: "center",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <ProgressBarChart
                                      value={95}
                                      maxValue={100}
                                    /> */}

                                      {item.paragraphData3.Chart1.chartType ===
                                        "line" && (
                                        <LineChart
                                          chatData={item.paragraphData3.Chart1}
                                        />
                                      )}

                                      {item.paragraphData3.Chart1.chartType ===
                                        "pie" && (
                                        <PieDonutChart
                                          chatData={item.paragraphData3.Chart1}
                                        />
                                      )}

                                      {item.paragraphData3.Chart1.chartType ===
                                        "bridge chart" && (
                                        <WaterfallChart
                                          chatData={item.paragraphData3.Chart1}
                                        />
                                      )}

                                      {item.paragraphData3.Chart1.chartType ===
                                        "Bar" && (
                                        <BarChart
                                          chatData={item.paragraphData3.Chart1}
                                        />
                                      )}
                                    </div>
                                  </Card>
                                )}

                                {/* Card for paragarphData4 */}
                                {item.paragraphData4 !== undefined && (
                                  <Card
                                    className="cardBoxCss"
                                    variant="outlined"
                                  >
                                    <div
                                      style={{
                                        display: "grid",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span className="headerTextItems">
                                          {item.paragraphData4.HeaderText}
                                        </span>
                                        <div className="like-dislike-container">
                                          <IconButton
                                            onClick={handleLike}
                                            color={
                                              liked ? "primary" : "default"
                                            }
                                          >
                                            <ThumbUpIcon />
                                          </IconButton>
                                          <Typography variant="subtitle1">
                                            {likeCount}
                                          </Typography>
                                          <IconButton
                                            onClick={handleDislike}
                                            color={
                                              disliked ? "primary" : "default"
                                            }
                                          >
                                            <ThumbDownIcon />
                                          </IconButton>
                                          <Typography variant="subtitle1">
                                            {dislikeCount}
                                          </Typography>
                                        </div>
                                      </span>
                                      <span className="headerTextItems1">
                                        {item.paragraphData4.paragraphText1}
                                      </span>
                                      <span className="headerTextItems1">
                                        {item.paragraphData4.paragraphText2}
                                      </span>
                                    </div>

                                    <div
                                      style={{
                                        display: "grid",
                                        alignItems: "center",
                                        alignContent: "center",
                                        width: "100%",
                                      }}
                                    >
                                      {item.paragraphData4.Chart1.chartType ===
                                        "line" && (
                                        <LineChart
                                          chatData={item.paragraphData4.Chart1}
                                        />
                                      )}

                                      {item.paragraphData4.Chart1.chartType ===
                                        "pie" && (
                                        <PieDonutChart
                                          chatData={item.paragraphData4.Chart1}
                                        />
                                      )}

                                      {item.paragraphData4.Chart1.chartType ===
                                        "bridge chart" && (
                                        <WaterfallChart
                                          chatData={item.paragraphData4.Chart1}
                                        />
                                      )}

                                      {item.paragraphData4.Chart1.chartType ===
                                        "Bar" && (
                                        <BarChart
                                          chatData={item.paragraphData4.Chart1}
                                        />
                                      )}
                                    </div>
                                  </Card>
                                )}

                                {item.paragraphData5 !== undefined && (
                                  <Card
                                    className="cardBoxCss"
                                    variant="outlined"
                                  >
                                    <div
                                      style={{
                                        display: "grid",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span className="headerTextItems">
                                          {item.paragraphData5.HeaderText}
                                        </span>
                                        <div className="like-dislike-container">
                                          <IconButton
                                            onClick={handleLike}
                                            color={
                                              liked ? "primary" : "default"
                                            }
                                          >
                                            <ThumbUpIcon />
                                          </IconButton>
                                          <Typography variant="subtitle1">
                                            {likeCount}
                                          </Typography>
                                          <IconButton
                                            onClick={handleDislike}
                                            color={
                                              disliked ? "primary" : "default"
                                            }
                                          >
                                            <ThumbDownIcon />
                                          </IconButton>
                                          <Typography variant="subtitle1">
                                            {dislikeCount}
                                          </Typography>
                                        </div>
                                      </span>
                                      <span className="headerTextItems1">
                                        {item.paragraphData5.paragraphText1}
                                      </span>
                                      <span className="headerTextItems1">
                                        {item.paragraphData5.paragraphText2}
                                      </span>
                                    </div>

                                    <div
                                      style={{
                                        display: "grid",
                                        alignItems: "center",
                                        alignContent: "center",
                                        width: "100%",
                                      }}
                                    >
                                      {item.paragraphData5.Chart1.chartType ===
                                        "line" && (
                                        <LineChart
                                          chatData={item.paragraphData5.Chart1}
                                        />
                                      )}

                                      {item.paragraphData5.Chart1.chartType ===
                                        "pie" && (
                                        <PieDonutChart
                                          chatData={item.paragraphData5.Chart1}
                                        />
                                      )}

                                      {item.paragraphData5.Chart1.chartType ===
                                        "bridge chart" && (
                                        <WaterfallChart
                                          chatData={item.paragraphData5.Chart1}
                                        />
                                      )}

                                      {item.paragraphData5.Chart1.chartType ===
                                        "Bar" && (
                                        <BarChart
                                          chatData={item.paragraphData5.Chart1}
                                        />
                                      )}
                                    </div>
                                  </Card>
                                )}

                                {item.paragraphData6 !== undefined && (
                                  <Card
                                    className="cardBoxCss"
                                    variant="outlined"
                                  >
                                    <div
                                      style={{
                                        display: "grid",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span className="headerTextItems">
                                          {item.paragraphData6.HeaderText}
                                        </span>
                                        <div className="like-dislike-container">
                                          <IconButton
                                            onClick={handleLike}
                                            color={
                                              liked ? "primary" : "default"
                                            }
                                          >
                                            <ThumbUpIcon />
                                          </IconButton>
                                          <Typography variant="subtitle1">
                                            {likeCount}
                                          </Typography>
                                          <IconButton
                                            onClick={handleDislike}
                                            color={
                                              disliked ? "primary" : "default"
                                            }
                                          >
                                            <ThumbDownIcon />
                                          </IconButton>
                                          <Typography variant="subtitle1">
                                            {dislikeCount}
                                          </Typography>
                                        </div>
                                      </span>
                                      <span className="headerTextItems1">
                                        {item.paragraphData6.paragraphText1}
                                      </span>
                                      <span className="headerTextItems1">
                                        {item.paragraphData6.paragraphText2}
                                      </span>
                                    </div>

                                    <div
                                      style={{
                                        display: "grid",
                                        alignItems: "center",
                                        alignContent: "center",
                                        width: "100%",
                                      }}
                                    >
                                      {item.paragraphData6.Chart1.chartType ===
                                        "line" && (
                                        <LineChart
                                          chatData={item.paragraphData6.Chart1}
                                        />
                                      )}

                                      {item.paragraphData6.Chart1.chartType ===
                                        "pie" && (
                                        <PieDonutChart
                                          chatData={item.paragraphData6.Chart1}
                                        />
                                      )}

                                      {item.paragraphData6.Chart1.chartType ===
                                        "bridge chart" && (
                                        <WaterfallChart
                                          chatData={item.paragraphData6.Chart1}
                                        />
                                      )}

                                      {item.paragraphData6.Chart1.chartType ===
                                        "Bar" && (
                                        <BarChart
                                          chatData={item.paragraphData6.Chart1}
                                        />
                                      )}
                                    </div>
                                  </Card>
                                )}
                              </div>
                            </div>
                          </Box>
                        </div>
                      ))}

                      {data.length !== 0 && (
                        <div
                          style={{
                            textAlign: "left",
                            paddingTop: "10px",
                            display: "grid",
                            gridGap: "10px",
                            padding: "12px",
                          }}
                        >
                          {!confirmations && (
                            <>
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "600",
                                }}
                              >
                                Do you want to print this Report ?
                              </span>

                              <div style={{ display: "flex", gridGap: "10px" }}>
                                <button
                                  className="submit_btn formLableText1"
                                  onClick={(e) => {
                                    printSegment();
                                    setyesDownload(!yesDownload);
                                    setconfirmations(!confirmations);
                                  }}
                                >
                                  Yes
                                </button>
                                <button
                                  className="submit_btn formLableText1"
                                  onClick={(e) =>
                                    setconfirmations(!confirmations)
                                  }
                                >
                                  No
                                </button>
                              </div>
                            </>
                          )}

                          {yesDownload && (
                            <div style={{ fontSize: "18px" }}>
                              Please click{" "}
                              <span
                                style={{ color: "blue", cursor: "pointer" }}
                                onClick={printSegment}
                              >
                                here
                              </span>{" "}
                              to print your report
                            </div>
                          )}

                          {confirmations && (
                            <>
                              <div
                                style={{
                                  fontSize: "16px",
                                  fontStyle: "italic",
                                }}
                              >
                                Thank you for your confirmations. Please
                                remember to provide overall feedback on the
                                report.
                              </div>
                              <StyledRating
                                name="highlight-selected-only"
                                defaultValue={0}
                                IconContainerComponent={IconContainer}
                                getLabelText={(value) =>
                                  customIcons[value].label
                                }
                                highlightSelectedOnly
                              />
                            </>
                          )}
                        </div>
                      )}

                      {data.length === 0 && (
                        <div>
                          {History.length === 0 && (
                            <div
                              style={{
                                height: "50vh",
                                display: "grid",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "absolute",
                                top: 30,
                                left: 0,
                                right: 0,
                                bottom: 0,
                              }}
                            >
                              <div>
                                <img
                                  src={nodataImage}
                                  width={250}
                                  alt="nodata"
                                />
                              </div>
                              <div
                                style={{
                                  display: "grid",
                                  gridGap: "6px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignContent: "center",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "20px",
                                    color: "gray",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  No response Available
                                </span>
                                <span
                                  style={{
                                    width: "100%",
                                    display: "block",
                                    margin: "auto",
                                    color: "gray",
                                    fontSize: "14px",
                                  }}
                                >
                                  {" "}
                                  Your questions will help to address any query
                                  or concerns
                                </span>
                              </div>
                            </div>
                          )}

                          {History.length !== 0 && (
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <span className="Recent_history">
                                  Recent Search
                                </span>
                                <span
                                  style={{
                                    justifyContent: "flex-end",
                                    paddingRight: "1rem",
                                    cursor: "pointer",
                                  }}
                                  className="Recent_Clear"
                                  onClick={clearSessionData}
                                >
                                  Clear
                                </span>
                              </div>

                              {History.slice()
                                .reverse()
                                .slice(0, 5)
                                .map((items, index) => {
                                  return (
                                    <div className="historyData" key={index}>
                                      <div className="historyItems">
                                        <span>
                                          <HistoryIcon
                                            sx={{ color: "#253978" }}
                                          />
                                        </span>
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) =>
                                            handleExampleClick(items)
                                          }
                                        >
                                          {items}
                                        </span>
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            display: "grid",
                                          }}
                                          onClick={(e) =>
                                            removeFromSessionHistory(items)
                                          }
                                        >
                                          <FaTrash
                                            fontSize={12}
                                            style={{ color: "#253978" }}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}

                  {!isLoading && errorData && (
                    <div
                      style={{
                        height: "90vh",
                        display: "grid",
                        justifyContent: "flex-start",
                        alignItems: "baseline",
                        alignContent: "baseline",
                        padding: "20px",
                        background: "white",
                        overflow: "hidden",
                        overflowY: "none",
                      }}
                    >
                      Something went wrong on the ask question {AskQuestion}
                    </div>
                  )}
                </div>

                <div className="col-12 inputTextboxHome">
                  <div className="input-group mt-0 mb-0">
                    <input
                      id="chat_pdf_input"
                      placeholder="Send a Message"
                      className="message-type-box form-control border-0"
                      type="text"
                      autoComplete="off"
                      value={userInput}
                      onChange={(e) => setUserInput(e.target.value)}
                    />
                    <span
                      id="send_pdf_chat"
                      className="btn btn-light-success text-success sendbtn borderColorNew"
                      onClick={handleSendClick}
                    >
                      <SendIcon
                        style={{ color: "#bb1ccc" }}
                        className="colorCss"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Home;
