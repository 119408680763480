import React, { useEffect, useState } from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';

import {
  FaCog,
  FaDatabase,
  FaHome,
  FaQuestionCircle,
  FaRegChartBar,
  FaSignInAlt,
  FaUserAlt,
  FaDna,
  FaUsersCog,
  FaChartArea,
  FaChartBar,
  FaChartLine,
  FaCommentDots,
  FaRegCommentDots
} from "react-icons/fa";
import Topnavbar from "./Topnavbar";
import profile from "../assets/img/User.png";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Getusers from "../auth/Getusers";
// import EvaluationIcon from '../assets/img/evaluation.png';

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  // backgroundImage: `linear-gradient(rgba(0,0,160,0.5), rgba(47, 46, 53,0.9)),url(${bgimg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  background: "black",
  color: "white",
  overflowX: "hidden",
  borderRight: "1px dotted rgba(86,88,105, 1) !important",
  "&& .Mui-selected": {
    outline: "0px",
    border: "0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    display: "flex",
    flexGrow: "1",
    justifyContent: "flex-start",
    alignItems: "center",
    textDecoration: "none",
    boxsizing: "border-box",
    textAlign: "left",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "relative",
    textTransform: "capitalize",
    color: "rgb(32, 101, 209);",
    borderRadius: "8px !important",
    backgroundcColor: "#0000a0",
    fontWeight: "700 !important",
  },
  "&& .logoutLayout": {
    // add any additional styles for logoutLayout class here
    left: "20px !important",
    position:"Absolute",
    bottom:"20px"
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "black",
  color: "white",
  overflowX: "hidden",
  borderRight: "1px dotted rgba(86,88,105, 1) !important",
  padding: "0px",

  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 20px)`,
  },
  "&& .Mui-selected": {
    borderRadius: "8px !important",
  },
  "&& .logoutLayout": {
    // add any additional styles for logoutLayout class here
    left: "8px !important",
    position:"Absolute",
    bottom:"20px"

  },
});

const openbox = (theme) => ({
  left: "286px !important",
});

const closebox = (theme) => ({
  left: "72px !important",
});

const listTogglebox = (theme) => ({
  padding: "0 !important",
  paddingTop: "2rem !important",
  padding: "1rem .5rem 0rem !important",
  borderRadius: "20px",
});

const listTogglebox1 = (theme) => ({
  display: "none !important",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(4, 2.6),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "noWrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    "& .navButtonToggle": openbox(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    "& .navButtonToggle": closebox(theme),
    "& .listToggle": listTogglebox(theme),
    "& .listToggle1": listTogglebox1(theme),
  }),
}));



const SideNavbar = ({ LoggedinUser }) => {
  const [open, setOpen] = useState(false);
  const [openDialog, setopenDialog] = useState(false);

  const { oktaAuth } = useOktaAuth();

  const logout = async () => {
    // Will redirect to Okta to end the session then redirect back to the configured `postLogoutRedirectUri`
    await oktaAuth.signOut();
  };

  const { pathname } = useLocation();

  const MenuItems = [
    {
      menu: "Home",
      route: "/",
      icon: <FaRegCommentDots className="colorCss" fontSize={24} />,
    },
    {
      menu: "KnowledgeGraph",
      route: "/KnowledgeGraph",
      icon: <FaChartLine className="colorCss" fontSize={24} />,
    },
    {
      menu: "Dashboard",
      route: "/Dashboard",
      icon: <FaChartBar className='colorCss' fontSize={24}/>,
    },
    {
      menu: "Workbench",
      route: "/Workbench",
      icon: <FaCog className='colorCss' fontSize={24}/>,
    }

  ];

  const [profileImage, setProfileImage] = useState(() => {
    // Get the profile image from localStorage on component mount
    const storedImage = localStorage.getItem("profileImage");
    return storedImage !== null ? storedImage : profile;
  });

  useEffect(() => {
    // Save the profile image to localStorage whenever it changes
    localStorage.setItem("profileImage", profileImage);
  }, [profileImage]);

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];

    if (selectedImage) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setProfileImage(reader.result);
      };

      reader.readAsDataURL(selectedImage);
    }
  };


 
 
  return (
    <div id="sidenavBarnew">
      <Box sx={{ display: "flex" }}>
        <Topnavbar open={open} />

        <Drawer className="sidenavBarstyle" variant="permanent" open={open}>
          <IconButton
            className="navButtonToggle bgColorNew borderColorNew"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open === false ? (
              <ChevronRightIcon className="colorCss" />
            ) : (
              <ChevronLeftIcon className="colorCss" />
            )}
          </IconButton>
          {open && (
            <>
              <div style={{ position: "relative", marginTop: "0px" }}>
                <div
                  style={{
                    height: "250px",
                    backgroundRepeat: "no-repeat",
                    paddingTop: "2rem",
                    marginTop: "0px",
                  }}
                >
                  <img
                    style={{
                      position: "absolute",
                      margin: "auto",
                      display: "block",
                      left: "0",
                      right: "0",
                      top: "25%",
                      borderRadius: "100%",
                      width: "150px",
                      height: "150px",
                      background: "#eee",
                    }}
                    src={profileImage || profile} // Use the selected image if available, else use the imported profile image
                    alt="Profile"
                  />
                </div>

                <input
                  type="file"
                  id="imageInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />

                <button
                  className="btnSelectimg"
                  onClick={() => document.getElementById("imageInput").click()}
                >
                  <CameraAltIcon
                    sx={{
                      color: "#0000a0",
                      background: "white",
                      borderRadius: "6px",
                    }}
                  />
                </button>
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
             <Getusers className="sidenavName" name={true}/>
              </div>
              <span
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
             <Getusers className="sidenavEmail" email={true}/>
              </span>
            </>
          )}

          {!open && (
            <>
              <div
                style={{
                  position: "relative",
                  marginTop: "0px",
                  height: "110px",
                }}
              >
                <div
                  style={{
                    paddingTop: "2rem",
                    marginTop: "0px",
                    paddingBottom: "1rem",
                  }}
                >
                  <img
                    style={{
                      position: "absolute",
                      margin: "auto",
                      display: "block",
                      left: "0",
                      right: "0",
                      top: "20%",
                      borderRadius: "100%",
                      width: "50px",
                      background: "#eee",
                      height:"50px"
                    }}
                    src={profileImage || profile}
                    alt="Profile"
                  />
                </div>
              </div>
            </>
          )}

          <List
            className="listToggle"
            style={{
              padding: "1.3rem",
              display: "grid",
              gridGap: "1rem",
              paddingTop: "2rem",
              alignContent:"baseline",
              alignItems:"baseline"
            }}
          >
       
            {MenuItems.map((menuItem, index) => {
          
              return (
                <Link
                  key={index}
                  style={{ color: "white" }}
                  to={`${menuItem.route}`}
                >
                  <ListItem
                    className="itemsmenulist colorCss"
                    selected={menuItem.route === pathname}
                    disablePadding
                    sx={{ display: "block" }}
                    title={menuItem.menu}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 53,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                        className="colorCss"
                      >
                        {menuItem.icon}
                      </ListItemIcon>
                      <ListItemText
                        className="listToggle1"
                        primary={menuItem.menu}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
              );
            })}

            <div
              className="logoutLayout"
              
            >
          

              <ListItem
                className="itemsmenulist itemsmenulist2"
                title="Logout"

                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  setopenDialog(!openDialog);
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 53,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <FaSignInAlt className="colorCss" fontSize={20} />
                  </ListItemIcon>
                  <ListItemText
                    className="listToggle1 colorCss"
                    primary="Logout"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </div>
          </List>
        </Drawer>

        <Dialog
          open={openDialog}
          onClose={() => {
            setopenDialog(!openDialog);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id="logoutPopup"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to logout ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please confirm your decision.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setopenDialog(!openDialog);
              }}
            >
              No
            </Button>
            <Button onClick={logout}>
                Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default SideNavbar;
