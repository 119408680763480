import React from 'react';
import ReactApexChart from 'react-apexcharts';

const PieDonutChart = (props) => {
  const Data = props.chatData
  const xaxisData = Data.chartData?.X_axis
  const yaxisData = Data.chartData?.Y_axis
  
  // console.log(xaxisData)
  // console.log(yaxisData)

  const options = {
    chart: {
      type: 'donut',
    },
    labels: xaxisData || Data.chartdata?.X_axis,
    legend: {
      position: 'bottom'
    },
    colors: ['#bb1ccc', '#283978', '#aabbcc', '#ff6347', '#00ff00', '#ffff00', '#ffa500'],

    plotOptions: {
      pie: {
        dataLabels: {
          position: 'bottom', // Set label position to 'bottom'
        },
      },
    },
  };

  const series = yaxisData  || Data.chartdata?.Y_axis

  return (
    <div>
      <ReactApexChart options={options} series={series} type="donut" height={350} />
    </div>
  );
};

export default PieDonutChart;
