// src/BarChart.js

import React from 'react';
import ReactApexChart from 'react-apexcharts';


const BarChart = (props) => {
    const data = props.chatData
    console.log(data)
  const options = {
    chart: {
      id: 'bar-chart',
      toolbar: {
        show: true
      }
    },
    xaxis: {
      categories: data.chartData.X_axis || data.chartdata.X_axis,
    },
    fill: {
        type: 'gradient',
        colors: ['#bb1ccc', '#E91E63', '#9C27B0'],
        gradient: {
            colorFrom: '#bb1ccc',
            colorTo: '#bb1ccc',
            stops: [0, 100],
            opacityFrom: 1,
            opacityTo: 0.5,
        },
    },
    plotOptions: {
      bar: {
        columnWidth: '50px',
      }
    }
    };

  const series = [
    {
      name: 'Data',
      data:  data.chartData.Y_axis || data.chartdata.Y_axis,
    },
  ];

  return (
    <div className="bar-chart">
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default BarChart;
