import React from 'react';
import ReactApexChart from 'react-apexcharts';

const LineChart = (props) => {
  const data = props.chatData;
  // console.log(data)
  const options = {
    chart: {
      type: 'line',
      height: 350,
      
    },
    colors: ['#bb1ccc', '#bb1ccc', '#bb1ccc', '#bb1ccc', '#bb1ccc'],
        fill: {
        type: 'gradient',

      },
    xaxis: {
      categories: data.chartData.X_axis,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Math.round(value); // Round the tick values to integers
        },
      },
    },
   
    markers: {
      size: 6, // Size of the circle marker
      colors: '#ffffff', // Fill color of the circle marker
      strokeWidth: 2, // Border width of the circle marker
      strokeColors: '#3d3d3d', // Border color of the circle marker
      fillColors: '#ff0000', // Fill color of the line (change to red)

    },
    stroke: {
        curve: 'smooth',
        dashArray: 2,

      },
    annotations: {
      yaxis: [
        {
          y: data.chartData.BaseLine, // Y-coordinate where you want to place the horizontal line
          borderColor: data.chartData.BaseLine === undefined ? "#ffffff" : '#ff0000', // Color of the line
          show:data.chartData.BaseLine === undefined ? false : true,
          label: {
            show: data.chartData.BaseLine === undefined ? false : true,
            text: data.chartData.BaseLineText === undefined ? '' : data.chartData.BaseLineText, // Label for the line
          },
        },
      ],
    },
  };

  const series = [
    {
      name: 'Series 1',
      data: data.chartData.Y_axis,
    },
  ];

  return (
    <div>
      <ReactApexChart options={options} series={series} type="line" height={350} width="100%"/>
    </div>
  );
};

export default LineChart;
